<template>
    <ValidationObserver ref="createOwnerPromo" v-slot="{ invalid }">
        <b-form @submit.prevent="save">
            <b-row class="mb-2 mt-1 border-bottom">
                <b-col md="3">
                    <ValidationProvider rules="required" name="nombre promocion">
                        <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                            <b-form-input
                                class="form-control"
                                type="text"
                                v-model="promoData.namePromo"
                                :state="errors.length == 0 && valid"
                            />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col md="3">
                    <validation-provider name="Hotel" rules="required">
                        <b-form-group slot-scope="{ valid, errors }" label="Seleccione hotel*">
                            <b-form-select
                                :state="errors.length == 0 && valid"
                                v-model="promoData.resort"
                            >
                                <option :value="null">Seleccione hotel</option>
                                <option
                                    v-for="hotel in hotels"
                                    :key="hotel.id"
                                    :value="hotel.id"
                                > {{ hotel.name }}
                                </option>
                            </b-form-select>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-col>

                <b-col md="3">
                    <ValidationProvider rules="required" name="codigo promocion">
                        <b-form-group label="Código de Promoción" slot-scope="{ valid, errors }">
                            <b-form-input
                                class="form-control"
                                type="text"
                                v-model="promoData.codePromo"
                                :state="errors.length == 0 && valid"
                            />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col md="3">
                    <ValidationProvider rules="required" name="URL Promocion">
                        <b-form-group label="URL Promoción" slot-scope="{ valid, errors }">
                            <b-form-input
                                class="form-control"
                                type="text"
                                v-model="promoData.url"
                                :state="errors.length == 0 && valid"
                            />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col md="3">
                    <ValidationProvider :rules="fileRule" name="archivo">
                        <b-form-group label="Archivo" slot-scope="{ valid, errors }" >
                            <b-form-file
                                v-model="imagePromo"
                                placeholder="Adjunte un archivo..."
                                accept="image/*"
                                drop-placeholder="Drop file here..."
                                :state="errors.length == 0 && valid"
                                browse-text="🔎"
                                @input="setImgFile(imagePromo)"
                            />
                            <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col md="3">
                    <ValidationProvider rules="required" name="Fecha Inicio">
                        <b-form-group label="Fecha Inicio" slot-scope="{ valid, errors }">
                            <b-form-input
                                type="date"
                                v-model="promoData.startDate"
                                min="today"
                                :state="errors.length == 0 && valid"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col md="3">
                    <ValidationProvider rules="required" name="Fecha Fin">
                        <b-form-group label="Fecha Fin" slot-scope="{ valid, errors }">
                            <b-form-input
                                type="date"
                                v-model="promoData.endDate"
                                min="today"
                                :state="errors.length == 0 && valid"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col md="3" class="mt-1">
                    <ValidationProvider name="" rules="required">
                        <b-form-group slot-scope="{ errors }">
                            <b-form-checkbox
                                :value="true"
                                v-model="promoData.status"
                                class="mt-1"
                            > Activado
                            </b-form-checkbox>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col md="6">
                    <b-row>
                        <b-col md="" v-if="!!promoData.imagePromo">
                            <b-card-text class="my-1">
                                Archivo seleccionado: <b-link @click="showImage"><strong>{{ showActualFileName }}</strong></b-link>
                                <!-- Archivo seleccionado: <strong>{{ showActualFileName }}</strong> -->
                            </b-card-text>
                        </b-col>
                        <!-- <b-col md="" v-if="!!promoData.imagePromo && !!sourceFile">
                            <b-img :src="sourceFile" thumbnail height="125" width="125" fluid alt="Responsive image" @click="showImage" />
                        </b-col> -->
                    </b-row>
                </b-col>


                <b-col md="12"/>

                <b-col md="" class="mt-1">
                    <ValidationProvider name="" rules="required">
                        <b-form-group slot-scope="{ errors }" label="Redes sociales para compartir">
                            <b-form-checkbox-group v-model="promoData.socialMedia">
                                <b-form-checkbox
                                    v-for="sn in socialNetworks"
                                    :key="sn.id"
                                    :value="sn.id"
                                > <feather-icon
                                    size="18"
                                    :icon="sn.name.charAt(0).toUpperCase()+sn.name.slice(1).toLowerCase()+'Icon'"
                                /> {{sn.name}}
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>

            <h5>Detalles</h5>
            <b-tabs>
                <!-- Render Tabs, supply a unique `key` to each tab -->
                <b-tab v-for="(detail, index) in promoData.promoDetails" :key="detail.id ? detail.id : detail.key">
                    <template #title>
                        Detalle {{index + 1}}
                        <b-button variant="danger" class="custom-close-tab-button float-right" v-if="index != 0" @click="closeTab(detail)">
                            x
                        </b-button>
                    </template>

                    <b-row>
                        <b-col md="3">
                            <ValidationProvider rules="required" name="titulo">
                                <b-form-group label="Título" slot-scope="{ valid, errors }">
                                    <b-form-input
                                        class="form-control"
                                        type="text"
                                        v-model="detail.title"
                                        :state="errors.length == 0 && valid"
                                    />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>

                        <b-col md="3">
                            <ValidationProvider name="idioma" rules="required">
                                <b-form-group label="Idioma*" slot-scope="{ valid, errors }">
                                    <b-form-select
                                        :state="(!errors[0] && !!detail.language && valid)"
                                        v-model="detail.language"
                                    >
                                        <option :value="null" disabled>Seleccione Idioma</option>
                                        <option v-for="lang in languages" :key="lang.id" :value="lang.id">
                                            {{ lang.name }}
                                        </option>
                                    </b-form-select>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="12"/>
                        <b-col md="">
                            <ValidationProvider rules="required" name="descripcion">
                                <b-form-group label="Descripción" slot-scope="{ valid, errors }">
                                    <b-form-textarea
                                        class="form-control"
                                        type="text"
                                        v-model="detail.description"
                                        :state="errors.length == 0 && valid"
                                    />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>


                </b-tab>

                <!-- New Tab Button (Using tabs-end slot) -->
                <template #tabs-end>
                    <b-nav-item role="presentation" @click.prevent="newTab" href="#" v-if="promoData.promoDetails.length < 2"><b>+</b></b-nav-item>
                </template>

                <!-- Render this if no tabs -->
                <template #empty>
                    <div class="text-center text-muted">
                        There are no open tabs<br>
                        Open a new tab using the <b>+</b> button above.
                    </div>
                </template>
            </b-tabs>

            <b-row>
                <b-col>
                    <div class="float-right">
                        <b-button
                            type="submit"
                            class="btn-block"
                            variant="primary"
                            :disabled="(invalid && !promoData.imagePromo) || isLoadingData"
                        > Guardar
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-form>
    </ValidationObserver>
</template>

<script>
import { mapState } from 'vuex'
import { stringAleatorio, getLocalFilePath } from '@/helpers/helpers';

export default {
    props: {
        promoData: {
            type: Object,
            required: true,
        },
        originalPromoData: {
            type: Object,
            required: false,
        },
        isLoadingData: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            description: '',
            tabs: [],
            imagePromo: null,
            tableColumns: [
                { key: 'housingname', label: 'Viviendas' },
            ],
        }
    },
    computed: {
        ...mapState('start', ['hotels','languages']),
        ...mapState('fivesClubCatalogs', ['socialNetworks']),
        filteredHotels() {
            return this.hotels
        },
        showActualFileName(){
            return this.promoData.fileName
        },
        sourceFile(){
            if (typeof this.promoData?.imagePromo != 'string') return getLocalFilePath(this.promoData.imagePromo)
            else return process.env.VUE_APP_IMG_SRC_API + this.promoData?.imagePromo
        },
        fileRule(){
            return (this.promoData?.imagePromo ? '' : 'required|') + 'image'
        }
    },
    methods: {
        closeTab(detail) {
            this.promoData.promoDetails = this.promoData.promoDetails.filter(promo => detail.id ? promo.id != detail.id : promo.key != detail.key)
        },
        newTab() {
            this.promoData.promoDetails.push({
                key: stringAleatorio(),
                language: null,
                title: null,
                description: null
            })
        },
        setImgFile(file){
            if(file){
                // remuevo la extension del archivo
                this.promoData.imagePromo = file
                this.promoData.fileName = file.name.replace(/\.[^\/.]+$/, '')
            }
            if (!file) {
                this.promoData.imagePromo = null
                this.promoData.fileName = null
            }
        },
        showImage(){
            const h = this.$createElement
            // Using HTML string
            const titleVNode = h('div', { domProps: { innerHTML: 'Vista previa: '+this.promoData.fileName } })
            // More complex structure
            const messageVNode = h('div', { class: ['foobar'] }, [
                h('b-img', {
                    props: {
                        src: this.sourceFile,
                        thumbnail: true,
                        center: true,
                        fluid: true,
                    }
                })
            ])
            // We must pass the generated VNodes as arrays
            this.$bvModal.msgBoxOk([messageVNode], {
                title: [titleVNode],
                buttonSize: 'sm',
                centered: true,
            })
        },
        save(){
            this.$emit('save-promo', this.promoData)
        }
    },
};
</script>

<style>
.custom-close-tab-button{
    padding: 0.1rem 0.3rem;
    margin-left: 0.5rem;
}
</style>