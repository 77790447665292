var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"createOwnerPromo",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('b-row',{staticClass:"mb-2 mt-1 border-bottom"},[_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"nombre promocion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nombre"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","state":errors.length == 0 && valid},model:{value:(_vm.promoData.namePromo),callback:function ($$v) {_vm.$set(_vm.promoData, "namePromo", $$v)},expression:"promoData.namePromo"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Hotel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Seleccione hotel*"}},[_c('b-form-select',{attrs:{"state":errors.length == 0 && valid},model:{value:(_vm.promoData.resort),callback:function ($$v) {_vm.$set(_vm.promoData, "resort", $$v)},expression:"promoData.resort"}},[_c('option',{domProps:{"value":null}},[_vm._v("Seleccione hotel")]),_vm._l((_vm.hotels),function(hotel){return _c('option',{key:hotel.id,domProps:{"value":hotel.id}},[_vm._v(" "+_vm._s(hotel.name)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"codigo promocion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Código de Promoción"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","state":errors.length == 0 && valid},model:{value:(_vm.promoData.codePromo),callback:function ($$v) {_vm.$set(_vm.promoData, "codePromo", $$v)},expression:"promoData.codePromo"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"URL Promocion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"URL Promoción"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","state":errors.length == 0 && valid},model:{value:(_vm.promoData.url),callback:function ($$v) {_vm.$set(_vm.promoData, "url", $$v)},expression:"promoData.url"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":_vm.fileRule,"name":"archivo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Archivo"}},[_c('b-form-file',{attrs:{"placeholder":"Adjunte un archivo...","accept":"image/*","drop-placeholder":"Drop file here...","state":errors.length == 0 && valid,"browse-text":"🔎"},on:{"input":function($event){return _vm.setImgFile(_vm.imagePromo)}},model:{value:(_vm.imagePromo),callback:function ($$v) {_vm.imagePromo=$$v},expression:"imagePromo"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Fecha Inicio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Fecha Inicio"}},[_c('b-form-input',{attrs:{"type":"date","min":"today","state":errors.length == 0 && valid},model:{value:(_vm.promoData.startDate),callback:function ($$v) {_vm.$set(_vm.promoData, "startDate", $$v)},expression:"promoData.startDate"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Fecha Fin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Fecha Fin"}},[_c('b-form-input',{attrs:{"type":"date","min":"today","state":errors.length == 0 && valid},model:{value:(_vm.promoData.endDate),callback:function ($$v) {_vm.$set(_vm.promoData, "endDate", $$v)},expression:"promoData.endDate"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"value":true},model:{value:(_vm.promoData.status),callback:function ($$v) {_vm.$set(_vm.promoData, "status", $$v)},expression:"promoData.status"}},[_vm._v(" Activado ")]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[(!!_vm.promoData.imagePromo)?_c('b-col',{attrs:{"md":""}},[_c('b-card-text',{staticClass:"my-1"},[_vm._v(" Archivo seleccionado: "),_c('b-link',{on:{"click":_vm.showImage}},[_c('strong',[_vm._v(_vm._s(_vm.showActualFileName))])])],1)],1):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"12"}}),_c('b-col',{staticClass:"mt-1",attrs:{"md":""}},[_c('ValidationProvider',{attrs:{"name":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Redes sociales para compartir"}},[_c('b-form-checkbox-group',{model:{value:(_vm.promoData.socialMedia),callback:function ($$v) {_vm.$set(_vm.promoData, "socialMedia", $$v)},expression:"promoData.socialMedia"}},_vm._l((_vm.socialNetworks),function(sn){return _c('b-form-checkbox',{key:sn.id,attrs:{"value":sn.id}},[_c('feather-icon',{attrs:{"size":"18","icon":sn.name.charAt(0).toUpperCase()+sn.name.slice(1).toLowerCase()+'Icon'}}),_vm._v(" "+_vm._s(sn.name)+" ")],1)}),1),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1)],1),_c('h5',[_vm._v("Detalles")]),_c('b-tabs',{scopedSlots:_vm._u([{key:"tabs-end",fn:function(){return [(_vm.promoData.promoDetails.length < 2)?_c('b-nav-item',{attrs:{"role":"presentation","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.newTab($event)}}},[_c('b',[_vm._v("+")])]):_vm._e()]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-muted"},[_vm._v(" There are no open tabs"),_c('br'),_vm._v(" Open a new tab using the "),_c('b',[_vm._v("+")]),_vm._v(" button above. ")])]},proxy:true}],null,true)},_vm._l((_vm.promoData.promoDetails),function(detail,index){return _c('b-tab',{key:detail.id ? detail.id : detail.key,scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Detalle "+_vm._s(index + 1)+" "),(index != 0)?_c('b-button',{staticClass:"custom-close-tab-button float-right",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.closeTab(detail)}}},[_vm._v(" x ")]):_vm._e()]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"titulo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Título"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","state":errors.length == 0 && valid},model:{value:(detail.title),callback:function ($$v) {_vm.$set(detail, "title", $$v)},expression:"detail.title"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"idioma","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Idioma*"}},[_c('b-form-select',{attrs:{"state":(!errors[0] && !!detail.language && valid)},model:{value:(detail.language),callback:function ($$v) {_vm.$set(detail, "language", $$v)},expression:"detail.language"}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Seleccione Idioma")]),_vm._l((_vm.languages),function(lang){return _c('option',{key:lang.id,domProps:{"value":lang.id}},[_vm._v(" "+_vm._s(lang.name)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}}),_c('b-col',{attrs:{"md":""}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"descripcion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Descripción"}},[_c('b-form-textarea',{staticClass:"form-control",attrs:{"type":"text","state":errors.length == 0 && valid},model:{value:(detail.description),callback:function ($$v) {_vm.$set(detail, "description", $$v)},expression:"detail.description"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1)],1)],1)}),1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"float-right"},[_c('b-button',{staticClass:"btn-block",attrs:{"type":"submit","variant":"primary","disabled":(invalid && !_vm.promoData.imagePromo) || _vm.isLoadingData}},[_vm._v(" Guardar ")])],1)])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }