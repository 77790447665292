<template>
    <b-overlay :show="isLoadingData || isSavingOwnerPromo">
        <b-card>
            <b-row>
                <b-col><h4>Formulario de creación</h4></b-col>
                <b-col>
                    <div class="head-btn">
                        <b-button
                            :to="{ name: 'fives-vacations'}"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="warning"
                        >
                            <feather-icon icon="ArrowLeftIcon" style="color: white" />
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <ownerPromoForm :promoData="data" :isLoadingData="isSavingOwnerPromo" @save-promo="save" />
        </b-card>
    </b-overlay>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { showAlertMessage, stringAleatorio } from '@/helpers/helpers';
import ownerPromoForm from '@/modules/fivesClub/components/catalogs/ownersWebsite/ownerPromoForm'
import Ripple from 'vue-ripple-directive'

export default {
    directives: {Ripple, },
    components: {
        ownerPromoForm
    },
    async created(){
        await this.init()
    },
    data() {
        return {
            data:{
                namePromo: null,
                codePromo: null,
                resort: null,
                startDate: null,
                endDate: null,
                imagePromo: null,
                fileName: null,
                url: null,
                socialMedia: [],
                status: true,
                promoDetails: [{
                    key: stringAleatorio(),
                    language: null,
                    title: null,
                    description: null
                }]
            },
            isSavingOwnerPromo: false,
            isLoadingData: false
        };
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('start', ['hotels','languages']),
    },
    methods: {
        ...mapMutations('fivesClubCatalogs', ['setSocialNetworks']),
        ...mapActions('fivesClubCatalogs', ['getInitialContentForTheFivesVacations', 'saveOwnerPromos']),
        ...mapMutations('start', ['setHotels', 'setLanguages']),

        async init(){
            this.isLoadingData = true
            const { hotels, languages, socialNetworks } = await this.getInitialContentForTheFivesVacations()

            if( this.languages.length < 1) this.setLanguages(languages)
            if( this.hotels.length < 1) this.setHotels(hotels)
            this.setSocialNetworks(socialNetworks)

            this.isLoadingData = false
        },
        async save(data) {
            this.isSavingOwnerPromo = true;
            data.createdBy = this.user.idUser

            const response = await this.saveOwnerPromos(data);  // saving in backend
            if (response){
                const { status, message } = response
                if (status) {
                    showAlertMessage( 'Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right')
                    this.$router.push({ name: 'fives-vacations' })
                }
            }

            this.isSavingOwnerPromo = false

        },
    },
};
</script>

<style scoped>
.head-btn{
    display: flex;
    justify-content: flex-end;
}
</style>
